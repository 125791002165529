import LandingPage from "../../components/organisms/landing-page/landingPage.component";
const HomeRoute = () => {
	return (
		<div className=''>
			<LandingPage></LandingPage>
		</div>
	);
}

export default HomeRoute;
